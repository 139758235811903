@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Noto+Color+Emoji&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Bungee+Spice&display=swap");

html {
    @apply antialiased;
    @apply select-none;
    // @apply scroll-smooth;
    min-width: 62.5rem;
    min-height: 100vh;
    font-family: Inter, sans-serif;
}

body{
    @apply scrollbar-thin; 
    @apply scrollbar-thumb-base-300; 
    @apply scrollbar-track-base-100;
    @apply scrollbar-thumb-rounded-full;
    width: 100%;
    min-height: 100vh;
}

#root{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}